import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Layout from './components/layout/Layout';

// Custom loading component with animation
const PageLoader = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 z-50 flex items-center justify-center bg-white dark:bg-gray-900"
  >
    <motion.div
      animate={{
        scale: [1, 1.2, 1],
        rotate: [0, 360],
      }}
      transition={{
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full"
    />
  </motion.div>
);

// Replace direct imports with lazy loading
const Home = lazy(() => 
  new Promise(resolve => {
    setTimeout(() => {
      resolve(import('./pages/Home') as any);
    }, 500); // 1 second delay
  })
);

const Contact = lazy(() => 
  new Promise(resolve => {
    setTimeout(() => {
      resolve(import('./pages/Contact') as any);
    }, 500);
  })
);

const Projects = lazy(() => 
  new Promise(resolve => {
    setTimeout(() => {
      resolve(import('./pages/Projects') as any);
    }, 500);
  })
);
const About = lazy(() => 
  new Promise(resolve => {
    setTimeout(() => {
      resolve(import('./pages/About') as any);
    }, 500);
  })
);

function App() {
  return (
    <BrowserRouter>
      <AnimatePresence mode="wait">
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="contact" element={<Contact />} />
              <Route path="projects" element={<Projects />} />
              <Route path="about" element={<About />} />
            </Route>
          </Routes>
        </Suspense>
      </AnimatePresence>
    </BrowserRouter>
  );
}

export default App;