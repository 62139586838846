import { Link, useLocation } from 'react-router-dom';

const NavBar = () => {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path ? 
      'text-purple-400 border-purple-400' : 
      'text-gray-300 hover:text-purple-300 border-transparent';
  };

  return (
    <nav className="bg-gray-900 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo/Name - Hidden on mobile, visible from small breakpoint up */}
          <Link to="/" className="hidden sm:flex items-center space-x-2">
            <span className="text-xl font-bold bg-gradient-to-r from-purple-500 to-indigo-600 text-transparent bg-clip-text">
              NO
            </span>
          </Link>

          {/* Navigation Links - Centered on mobile */}
          <div className="flex space-x-8 mx-auto sm:mx-0">
            <Link
              to="/"
              className={`${isActive('/')} border-b-2 transition-colors duration-200 px-1 py-2`}
            >
              Home
            </Link>
            <Link
              to="/projects"
              className={`${isActive('/projects')} border-b-2 transition-colors duration-200 px-1 py-2`}
            >
              Projects
            </Link>
            <Link
              to="/about"
              className={`${isActive('/about')} border-b-2 transition-colors duration-200 px-1 py-2`}
            >
              About
            </Link>
            <Link
              to="/contact"
              className={`${isActive('/contact')} border-b-2 transition-colors duration-200 px-1 py-2`}
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
